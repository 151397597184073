export const SiteConfig = {
  queryParams: {
    dealerPartition: "dealer_partition",
    enablePrismicPreview: "enable_prismic_preview",
    dealerCode: "dealer",
    dealerId: "dealer_id",
    subdomain: "subdomain",
  },
  headers: {
    dealerPartition: "x-dealer-partition",
  },
  localStorage:{
    trackerArray: '__tracker_array'
  },
  cookies: {
    currentDealerId: "__current_dealer_id",
    currentDealerCode: "__current_dealer_code",
    dealerPartition: "__dealer_partition",
    disableGeo: "__disable_geo",
    sessionZip: "__session_zip",
    cacheSet: "__cache_set",
    phoneNumber: "__phone_number",
    trackerId: "__tracker_id",
    landingUrl: "__landing_url",
    cacheReloadTimer: "__cache_reload_timer",
    enablePrismicPreview: "__enable_prismic_preview",
    oneYearFromToday: () => {
      return new Date().setFullYear(new Date().getFullYear() + 1)
    },
    twoWeeksFromToday: () => {
      return new Date(Date.now() + 12096e5)
    },
  },
  cache: {
    // 30 Days
    revalidateTime: 60 * 60 * 24 * 30 * 1000,
    oneDay: 60 * 60 * 24 * 1000,
  },
  corporate: {
    phone: "8888042003",
  },
  logo: "https://ucarecdn.com/f4da14f7-8481-46bd-bbb1-906c8d9909ac/-/preview/337x337/"
}
