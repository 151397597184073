import cookie from "js-cookie"

import { SiteConfig } from "@/config/site"

export function setCurrentDealerId(id: any) {
  cookie.set(SiteConfig.cookies.currentDealerId, id.toString(), {
    expires: SiteConfig.cookies.oneYearFromToday(),
  })
}

export function setDealerPartition(id: any) {
  cookie.set(SiteConfig.cookies.dealerPartition, id.toString(), {
    expires: SiteConfig.cookies.oneYearFromToday(),
  })
}

export function clearCurrentDealerId() {
  cookie.remove(SiteConfig.cookies.currentDealerId)
  cookie.remove(SiteConfig.cookies.currentDealerCode)
  cookie.set(SiteConfig.cookies.disableGeo, "true", {
    expires: SiteConfig.cookies.oneYearFromToday(),
  })
}

export function setSessionZip(zip: string) {
  cookie.set(SiteConfig.cookies.sessionZip, zip, {
    expires: SiteConfig.cookies.oneYearFromToday(),
  })
}

export function getCacheSet() {
  const cache = cookie.get(SiteConfig.cookies.cacheSet)
  return cache === "true" ? true : false
}

export function setCacheSet() {
  cookie.set(SiteConfig.cookies.cacheSet, "true", {
    expires: SiteConfig.cookies.oneYearFromToday(),
  })
}

export function getCacheReloadTimer() {
  const timer = cookie.get(SiteConfig.cookies.cacheReloadTimer)
  return timer && timer.length > 0 ? parseInt(timer) ?? 1400 : 1400
}

export function getPhoneCookie() {
  const phoneNumberCookie = cookie.get(SiteConfig.cookies.phoneNumber)

  return phoneNumberCookie
}

export function clearPhoneNumberCookie() {
  cookie.remove(SiteConfig.cookies.phoneNumber)
}

export function setPhoneNumber(number: any) {
  cookie.set(SiteConfig.cookies.phoneNumber, number.toString(), {
    expires: SiteConfig.cookies.oneYearFromToday(),
  })
}

export function getTrackerIdCookie(): number | undefined {
  const trackerIdCookie = cookie.get(SiteConfig.cookies.trackerId)
  return trackerIdCookie ? +trackerIdCookie : undefined
}